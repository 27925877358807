<template>
    <div class="content">
        <slot />
    </div>
</template>

<style>
    .content {
        height: calc(100% - 3.5rem);
    }
</style>